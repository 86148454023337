// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore"; // Add Firestore import
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDUzvPmgCuHFPCg2ZhF6bjrzU14V_3u2PM",
  authDomain: "waitlist-c56d8.firebaseapp.com",
  databaseURL: "https://waitlist-c56d8-default-rtdb.firebaseio.com",
  projectId: "waitlist-c56d8",
  storageBucket: "waitlist-c56d8.appspot.com",
  messagingSenderId: "1097232387951",
  appId: "1:1097232387951:web:fcb53ecc1e41a3960ee29a",
  measurementId: "G-3P00K3NXBF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const firestore = getFirestore(app); // Export Firestore
