import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { ref, set } from 'firebase/database';
import { database } from './firebaseConfig'; // Import the database

const App: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeFeature, setActiveFeature] = useState('sidebar');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [progress, setProgress] = useState(0); // {{ edit_1 }}
  // const [isPlaying, setIsPlaying] = useState(false);
  // const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [thoughts, setThoughts] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // New state for success
  const [referrals, setReferrals] = useState(['', '', '']); // State for referrals

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
    }
  };

  const handleJoinWaitlist = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission

    const sanitizedEmail = email.replace(/\./g, '_'); // Sanitize email

    const waitlistData = {
      email: email,
      thoughts: thoughts,
    };

    try {
      await set(ref(database, 'yourFirebaseCollection/' + sanitizedEmail), waitlistData); // Use sanitized email as the key
      console.log("Successfully added to waitlist!");
      setIsSuccess(true); // Set success state to true
    } catch (error) {
      console.error("Error adding to waitlist: ", error);
    }
  };

  const handleReferralChange = (index: number, value: string) => {
    const newReferrals = [...referrals];
    newReferrals[index] = value;
    setReferrals(newReferrals);
  };

  return (
    <div className="kululo-prelaunch">
      <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
        <div className="header-content">
          <div className="logo">
            <img src="/revision2.png" alt="Kululo Logo" />
          </div>
          <nav className={`main-nav ${isMenuOpen ? 'open' : ''}`}>
            <ul>
              <li><a href="#features">Features</a></li>
              <li><a href="#vision">Vision</a></li>
              <li><a href="#signup">Early Access</a></li>
            </ul>
          </nav>
          <div className="auth-buttons">
            <a href="#sign-up" className="cta-button">Get Early Access</a>
          </div>
          <button className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </header>

      <main>
        <section className="hero">
          <div className="hero-content">
            <h1>The All-in-One Productivity Ecosystem</h1>
            <p>Kululo helps you finish tasks, collaborate seamlessly, and boost your productivity with specialized apps and universal tools.</p>
            <div className="hero-cta">
              <a href="#sign-up" className="cta-button">Get Early Access</a>
            </div>
          </div>
          <div className="hero-image">
            <img src="/placeholder.svg?height=600&width=800&text=Kululo Dashboard Mockup" alt="Kululo Platform Mockup" />
          </div>
        </section>

        <section className="social-proof">
          <p>Join thousands of early adopters from</p>
          <div className="logo-grid">
            {['Tech Startups', 'Creative Agencies', 'Freelancers', 'Students', 'Remote Teams', 'Entrepreneurs'].map((group) => (
              <div key={group} className="adopter-group">{group}</div>
            ))}
          </div>
        </section>

        <section className="video-section">
          <div className="video-container">
            <video
              ref={videoRef}
              onTimeUpdate={handleTimeUpdate}
              src="/beat.mp4"
              controls
            />
            <p>Progress: {progress}%</p> {/* {{ edit_2 }} */}
          </div>
        </section>

        <section className="features-showcase">
          <h2>Kululo's Productivity-Boosting Features</h2>
          <div className="features-nav">
            <button
              className={activeFeature === 'sidebar' ? 'active' : ''}
              onClick={() => setActiveFeature('sidebar')}
            >
              Specialized Sidebar Apps
            </button>
            <button
              className={activeFeature === 'universal' ? 'active' : ''}
              onClick={() => setActiveFeature('universal')}
            >
              Universal Tools
            </button>
            <button
              className={activeFeature === 'smart' ? 'active' : ''}
              onClick={() => setActiveFeature('smart')}
            >
              Smart Document Format
            </button>
          </div>
          <div className="feature-content">
            {activeFeature === 'sidebar' && (
              <div className="feature-item">
                <h3>Specialized Sidebar Apps</h3>
                <p>Access specific apps like LMS, DAW, and more, tailored to your unique workflow needs.</p>
                <img src="/placeholder.svg?height=400&width=600&text=Sidebar Apps Mockup" alt="Sidebar Apps Feature" />
              </div>
            )}
            {activeFeature === 'universal' && (
              <div className="feature-item">
                <h3>Universal Tools</h3>
                <p>Enjoy seamless integration of communication, storage, and task management tools across all apps.</p>
                <img src="/placeholder.svg?height=400&width=600&text=Universal Tools Mockup" alt="Universal Tools Feature" />
              </div>
            )}
            {activeFeature === 'smart' && (
              <div className="feature-item">
                <h3>Smart Document Format</h3>
                <p>Work with a flexible, block-based system that allows you to combine multiple apps within a single project.</p>
                <img src="/placeholder.svg?height=400&width=600&text=Smart Document Mockup" alt="Smart Document Feature" />
              </div>
            )}
          </div>
        </section>

        <section className="testimonial">
          <div className="testimonial-content">
            <blockquote>
              "Kululo's vision for an all-in-one productivity ecosystem is exactly what modern professionals and teams need. I can't wait to see it in action!"
            </blockquote>
            <div className="testimonial-author">
              <img src="/placeholder.svg?height=60&width=60" alt="Testimonial Author" />
              <div>
                <p className="author-name">Alex Johnson</p>
                <p className="author-title">CEO, TechInnovate</p>
              </div>
            </div>
          </div>
        </section>

        <section className="key-benefits">
          <h2>Why Choose Kululo</h2>
          <div className="benefits-grid">
            <div className="benefit-card">
              <img src="/placeholder.svg?height=50&width=50&text=Icon" alt="All-in-One Icon" />
              <h3>All-in-One Solution</h3>
              <p>Access all your productivity tools in one seamless platform.</p>
            </div>
            <div className="benefit-card">
              <img src="/placeholder.svg?height=50&width=50&text=Icon" alt="Customizable Icon" />
              <h3>Customizable Workflow</h3>
              <p>Tailor your workspace with specialized apps and universal tools.</p>
            </div>
            <div className="benefit-card">
              <img src="/placeholder.svg?height=50&width=50&text=Icon" alt="Collaboration Icon" />
              <h3>Enhanced Collaboration</h3>
              <p>Work together effortlessly with integrated communication and sharing features.</p>
            </div>
            <div className="benefit-card">
              <img src="/placeholder.svg?height=50&width=50&text=Icon" alt="AI-Powered Icon" />
              <h3>AI-Powered Assistance</h3>
              <p>Boost your productivity with ASEY, our intelligent search and task management AI.</p>
            </div>
          </div>
        </section>

        <section className="cta-section" id="sign-up">
          {isSuccess ? (
            <div className="success-message">
              <h2>Thank You for Joining the Waitlist!</h2>
              <p>You're all set! To help us grow, please refer up to three friends:</p>
              <div className="referral-inputs">
                {referrals.map((referral, index) => (
                  <input
                    key={index}
                    type="email"
                    placeholder={`Friend's Email ${index + 1}`}
                    value={referral}
                    onChange={(e) => handleReferralChange(index, e.target.value)}
                  />
                ))}
              </div>
              <button className="cta-button" onClick={() => alert('Referrals submitted!')}>Submit Referrals</button>
            </div>
          ) : (
            <div>
              <h2>Be Among the First to Experience Kululo</h2>
              <p>Join our waitlist and help shape the future of productivity</p>
              <form className="signup-form" onSubmit={handleJoinWaitlist}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <textarea
                  placeholder="Your Questions or Thoughts"
                  value={thoughts}
                  onChange={(e) => setThoughts(e.target.value)}
                  required
                />
                <button type="submit" className="cta-button">Join Waitlist</button>
              </form>
            </div>
          )}
        </section>

        <section className="faq">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-grid">
            {[
              { q: "What is Kululo?", a: "Kululo is an all-in-one productivity ecosystem that combines specialized apps and universal tools to help you finish tasks and boost your efficiency." },
              { q: "When will Kululo be available?", a: "We're currently in the pre-launch phase. Join our waitlist to be notified about early access and launch dates." },
              { q: "How is Kululo different from other productivity tools?", a: "Kululo offers a unique combination of specialized apps and universal tools in one platform, allowing for seamless workflow integration and customization." },
              { q: "Can I create my own apps on Kululo?", a: "Yes! Kululo will feature a marketplace where users can create, sell, and share their own specialized apps." }
            ].map((item, index) => (
              <div key={index} className="faq-item">
                <h3>{item.q}</h3>
                <p>{item.a}</p>
              </div>
            ))}
          </div>
        </section>
      </main>

      <footer>
        <div className="footer-content">
          <div className="footer-logo">
            <img src="/revision2.png" alt="Kululo Logo" />
          </div>
          <div className="footer-links">
            <div className="footer-column">
              <h3>Product</h3>
              <ul>
                <li><a href="#features">Features</a></li>
                <li><a href="#benefits">Benefits</a></li>
                <li><a href="#vision">Vision</a></li>
                <li><a href="#faq">FAQ</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <h3>Company</h3>
              <ul>
                <li><a href="#about">About</a></li>
                <li><a href="#team">Team</a></li>
                <li><a href="#careers">Careers</a></li>
                <li><a href="#contact">Contact</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <h3>Legal</h3>
              <ul>
                <li><a href="#privacy">Privacy Policy</a></li>
                <li><a href="#terms">Terms of Service</a></li>
                <li><a href="#cookies">Cookie Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Kululo. All rights reserved.</p>
          <div className="footer-social">
            <a href="#twitter" aria-label="Twitter">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
            </a>
            <a href="#linkedin" aria-label="LinkedIn">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;